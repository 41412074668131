<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		permanent
		app
		right
		style="z-index: 101"
		width="350px"
	>
		<v-list dense>
			<completion-rate
				:value="completion"
				v-if="!mini"
				:height="20"
			></completion-rate>
			<v-avatar
				v-else
				size="38"
				color="primary"
				class="ml-2 mb-1 white--text"
			>
				<small>{{ completion }}%</small>
			</v-avatar>

			<v-divider class="mt-1 mb-2"></v-divider>
			<template v-if="!mini">
				<section-navigation
					v-for="id in mySections"
					:id="id"
					:key="id"
				></section-navigation>
			</template>
		</v-list>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<assessment-buttons
				:mini="mini"
				@save="$emit('save')"
			></assessment-buttons>
			<v-list dense class="pl-1">
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-left' : 'mdi-chevron-right'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<style lang="less">
.v-list-item {
	padding: 0px 10px !important;
}
</style>
<script>
import NavItem from "@c/navigation/Item";
import SectionNavigation from "@c/navigation/assessment/sections/SectionNavigation";
import AssessmentButtons from "@c/navigation/assessment/Buttons";
import CompletionRate from "@c/navigation/assessment/CompletionRate";
export default {
	name: "AssessmentNavigation",
	data() {
		return {
			drawer: true,
			mini: false,
		};
	},
	computed: {
		mySections() {
			return this.$store.getters.mySections;
		},
		isDelegate() {
			return this.$store.getters.isDelegate;
		},
		assessment() {
			return this.$store.state.assessment.data;
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		completion() {
			return this.$store.getters.completionRate
		},
	},
	components: {
		CompletionRate,
		NavItem,
		SectionNavigation,
		AssessmentButtons,
	},
};
</script>
