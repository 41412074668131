<template>
	<v-list-item>
		<direction-buttons v-if="!mini"></direction-buttons>
		<filter-button v-if="!mini"></filter-button>
		<upload-evidence v-if="!mini"></upload-evidence>
		<complete-all v-if="!mini"></complete-all>
		<template v-if="current.response">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon outlined color="primary" class="ml-2" @click="$emit('save')">
						<v-icon>mdi-content-save</v-icon>
					</v-btn>
				</template>
				Save
			</v-tooltip>
		</template>
	</v-list-item>
</template>
<script>
import FilterButton from "@c/navigation/assessment/FilterButton";
import DirectionButtons from "@c/navigation/assessment/DirectionButtons";
import UploadEvidence from "@c/evidence-upload/UploadEvidence.vue";
import CompleteAll from "@c/survey/tools/CompleteAll.vue";
export default {
	name: "AssessmentButtons",
	props: {
		mini: { type: Boolean },
	},
	components: {
		FilterButton,
		DirectionButtons,
		UploadEvidence,
		CompleteAll
	},
	computed: {
		current() {
			return this.$store.state.current;
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		responses() {
			const self = this;
			let ids = self.$store.getters.order.map((o) => o.response);
			return ids.filter((id) => self.$store.state.sectionResponses.data[id]);
		},
	},
};
</script>
