<template>
	<mw-dialog
		v-model="dialog"
		submitBtn
		cancelBtn
		@submit="submit"
		title="Submit All"
	>
		<template v-slot:button v-if="canComplete.length">
			<v-tooltip top>
				<template v-slot:activator="{on}" >
				<v-btn v-on="on" color="green" outlined icon @click="dialog = true" :disabled="completion < 80">
					<v-icon >mdi-check</v-icon>
				</v-btn>
				</template>
				Submit Everything
			</v-tooltip>
		</template>
		<template v-slot:dialog-body>
			<render-content id="submitall"></render-content>
		</template>
	</mw-dialog>
</template>

<style lang="less">
.button {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";
import RenderContent from "@c/ui/RenderContent";

export default {
	name: "CompleteAll",
	data: () => {
		return {
			dialog: false,
			color: "green",
		};
	},
	computed: {
		responses(){
			return this.$store.state.sectionResponses.data;
		},
		order() {
			return this.$store.getters.order
		},
		completion(){
			return this.$store.getters.completionRate
		},
		canComplete(){
			const self = this;
			if( !self.responses ){
			return;
			}
			let responses = self.order.map( o => {
				return self.responses[o.response]
			})
			return responses.filter(r => r).filter( r => {
				return ["not_started", "in_progress", "returned", "revoked"].includes(r.status)
			}).map( r => r.id );
		}
	},
	components: {
		MwDialog,
		RenderContent
	},
	methods: {
		submit() {
			const self = this;
			self.canComplete.forEach( id => {
				self.$store
					.dispatch("sectionResponses/patch", {
						id: id,
						status: "complete",
					})
					.then(() => {
						self.dialog = false;
					});
			})
		},
	},
};
</script>
