<template>
	<v-sheet flat>
		<v-card flat class="mt-4 mb-4" color="lightest">
			<v-card-title>{{ section.name }}</v-card-title>
			<v-card-text v-html="intro"> </v-card-text>
		</v-card>
		<section-delegated v-if="isLead && isDelegated"></section-delegated>
		<section-complete v-if="isLead && isComplete"></section-complete>
		<h3 v-if="response.multiple">
			{{ responseName }}
		</h3>
		<v-sheet v-if="visibleQuestions.length">
			<questions :disabled="disabled" :ids="visibleQuestions" v-model="response.data" @error="error = true" @save="$emit('save')"></questions>
		</v-sheet>
		<p v-else>{{ $t("messages.no_questions_found") }}</p>
		<v-snackbar color="primary" v-model="error" text>{{ $t("error.section_disabled") }}</v-snackbar>
	</v-sheet>
</template>

<script>
import SectionDelegated from "@c/notices/SectionDelegated.vue";
import SectionComplete from "@c/notices/SectionComplete.vue";
import Questions from "@c/survey/render/Questions.vue";
export default {
	name: "SurveySection",
	data: () => {
		return {
			error: false,
		};
	},
	components: {
		Questions,
		SectionDelegated,
		SectionComplete,
	},
	computed: {
		current() {
			return this.$store.state.current;
		},
		assessment() {
			return this.$store.state.assessment.data;
		},
		organisation() {
			return this.$store.state.organisation.data;
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		isDelegated() {
			return this.response.status == "delegated";
		},
		isComplete() {
			return this.response.status == "complete";
		},
		section() {
			return this.$store.state.sections.data[this.current.section];
		},
		questions() {
			return this.$store.state.questions.data;
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data || {};
		},
		disabled() {
			return !this.$store.getters.canCurrentlyEdit;
		},
		response() {
			return this.$store.state.sectionResponses.data[this.current.response];
		},
		responseName() {
			var rule = this.$store.getters.currentMultipleRule;
			if (rule) {
				if (rule.name) {
					return rule.name;
				} else {
					return this.categoryOptions[rule.value].name || "";
				}
			}
			return "";
		},
		intro() {
			let intro = this.section.introduction;
			if (!this.response.multiple) {
				return intro;
			}
			var rule = this.$store.getters.currentMultipleRule;
			if (rule) {
				if (this.section.introductions && this.section.introductions[rule.value]) {
					intro = this.section.introductions[rule.value];
				}
			}
			return intro;
		},
		canPoll() {
			return this.$store.getters.canPoll;
		},
		visibleQuestions() {
			const self = this;
			if (!self.section.questions) {
				return [];
			}
			let questions = self.section.questions.filter((q) => {
				return self.testCategoryRules(q) && self.mwsurveyutils.isVisible(q, self.response);
			});
			questions = self.mwsurveyutils.hideAnswered(questions, self.response);
			return questions;
		},
		answeredQuestions() {
			const self = this;
			let answeredIds = Object.keys(self.response.data);
			answeredIds = answeredIds.filter( id => self.visibleQuestions.includes(id )  )
			let answered = answeredIds.filter((a) => typeof self.response.data[a] !== "undefined" && self.response.data[a].length);
			
			return answered.filter((q) => {
				let question = self.questions[q];
				let template = self.questionTemplates[question.question_template];
				return !template || !template.exclude_from_count;
			});
		},
		countedQuestions() {
			const self = this;
			return self.visibleQuestions.filter((q) => {
				let question = self.questions[q];
				let template = self.questionTemplates[question.question_template];
				return !template || !template.exclude_from_count;
			});
		},
		completionRate() {
			return (this.answeredQuestions.length / this.countedQuestions.length) * 100;
		},
	},
	methods: {
		testCategoryRules(id) {
			const self = this;
			let question = self.questions[id];
			if (self.mwsurveyutils.questionHasConditions(question)) {
				let serviceRules = question.rules.filter((r) => r.category == "service");
				if (serviceRules.length) {
					let hasService = serviceRules.find((rule) => {
						return self.mwutils.testRule(rule, self.organisation);
					});
					if (!hasService) {
						return false;
					}
				}
				return true;
			} else {
				return true;
			}
		},
	},
	watch: {
		completionRate(value) {
			let data = this.response.data;
			for (var key in data) {
				if (typeof data[key] == "undefined") {
					data[key] = "";
				}
			}
			let update = { id: this.current.response, completion_rate: value, data: data };
			if (this.response.status == "not_started") {
				update.status = "in_progress";
			}
			this.$store.dispatch("sectionResponses/patch", update);
		},
		current: {
			deep: true,
			immediate: true,
			handler() {
				let where = [
					["assessment", "==", this.assessment.id],
					["response", "==", this.current.response],
				];
				if (this.canPoll) {
					this.$store.dispatch("polls/fetchAndAdd", {
						clauses: {
							where: where,
						},
					});
				}
			},
		},
	},
};
</script>
